import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not found" />
    <p>That fennec doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
